$theme: core;
@use "design-system" as ds;

.container {
  @include ds.pad("regular");
  display: flex;
  justify-content: space-between;
  @include ds.border("divider--subtle", $b: 1px, $theme: $theme);
  border-bottom: solid 1px ds.color(ui, divider--subtle, $theme: $theme);
  border-radius: ds.spacing("micro") ds.spacing("micro") 0 0;
}

.header {
  @include ds.typography(h3, $theme, $theme);
}

.close {
  cursor: pointer;
  display: flex;
  align-items: center;
  @include ds.background(primary, $theme: $theme);
}
