$theme: core;
@use "design-system" as ds;

.container {
  @include ds.pad("regular");
  @include ds.background(card--commercial, $theme: $theme);
  border-radius: ds.spacing("micro");
  padding-top: ds.spacing("regular");
}

.messageFlex {
  display: flex;
  justify-content: space-between;
  margin-bottom: ds.spacing("base");
}

.message {
  width: 60%;
}

.iconContainer {
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bold {
  font-weight: bold;
}
